import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Works from '../components/Works';

const WorkPage = () => (
  <Layout>
    <SEO title="Work" />
    <Works />
  </Layout>
);

export default WorkPage;
